import GlobalStyle from './globalstyle';
import Colors from './colors';
import ImageStyle from './image';
import styled from 'styled-components';

const Container = GlobalStyle.BasicBlock.extend`
  display: ${({ display }) => display ? display : 'block'};
  width: ${({ width }) => width ? `${width}px` : 'auto'};
  height: ${({ height }) => height ? `${height}px` : 'auto'};
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? '0' : '16px'};
  cursor: pointer;
  margin: ${({ margin, marginValue = '' }) => marginValue
    ? marginValue
    : margin
      ? '20px 16px 0 16px'
      : ''
  };
  margin-right: ${({ needMarginRight }) => needMarginRight ? `${GlobalStyle.ViewSpace}px` : 0};
  border: ${props => props.border ? 'none' : ''};
  border-radius: ${props => props.sideBlock ? '0px' : ''};
  position: relative;
`;

const TileImg = ImageStyle.extend`
  width: ${({ width }) => width ? `${width}px` : 'auto'};
  height: ${({ height }) => height ? `${height}px` : 'auto'};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.column? 'column' : 'row'};
  justify-content: ${props => props.between ? 'space-between' : 'center'};
  align-items: center;
  padding: ${props => props.smallpadding ? '13px' : '20px'};
  padding-top: ${props => props.paddingTop ? '10px' : ''};
`;

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: ${props => props.column? 'column' : 'row'};
`;

const Text = styled.p`
  font-size: ${({ fontSize }) => fontSize ? `${fontSize}px` : 'inherit'};
  line-height: 1.5em;
  color: ${props => props.highlight ? '#F75C2F' : '#4a4a4a'};
  font-weight: ${props => props.bold ? 'bold' : ''};
  margin: 0;
  max-height: 3em;
  display: -webkit-box;
  overflow : hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Price = styled.div`
  font-size: 14px;
  min-width: 43px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${Colors.MAIN_COLOR};
`;

const Icon = styled.i`
  font-size: 20px;
  color: ${props => props.highlight ? '#F75C2F' : '#4a4a4a'};
  margin-right: 2.45px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ClickButton = styled.div`
  background-color: #F75C2F;
  border-radius: 2px;
  font-size: 13px;
  color: #FFFFFF;
  letter-spacing: 0;
  padding: 5px 25px;
  margin-bottom: 14px;
  cursor: pointer;
`;

const CartButton = styled.div`
  background-color: #F5F5F5;
  border: 0 solid #D9D9D9;
  border-radius: 2px;
  padding: 5px 9px;
  margin-bottom: 14px;
  font-weight: bold;
  color: ${Colors.Dark3};
`;

const HotTag = styled.div`
  background-color: #F75C2F;
  border-radius: 3px;
  font-size: 16px;
  color: #FFFFFF;
  letter-spacing: 0;
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 13px 3px 15px;
`;

export default {
    Container,
    TileImg,
    TextWrapper,
    Wrapper,
    Text,
    Price,
    Icon,
    Row,
    ClickButton,
    CartButton,
    HotTag,
};