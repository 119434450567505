import demoTestActionTypes from "actions/types/demoTestActionTypes";

/*==========================
    private method
==========================*/

// ------------------------------
// [完成] 取得測試資料
// ------------------------------
function recvGetDeomTestResult(result) {

    const payload = {
        result: result,
    };

    return { type: demoTestActionTypes.recvGetDeomTestResult, payload };
}

/*==========================
    public method
==========================*/

// ------------------------------
// 取得測試資料
// ------------------------------
function getDeomTest(data) {

    return function(dispatch) {

		const promise = new Promise((resolve, reject) => {

            import('mockup/demo.json').then((result) => {
                dispatch(recvGetDeomTestResult(result));
                resolve(true);
            }).catch(err => {
                reject(err);
            });
		});

        return promise;
	}
}

const demoTestActions = {
    getDeomTest, // 取得測試資料
}

export default demoTestActions;