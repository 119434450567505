import actionTypes from "actions/types/bonusActionTypes";
import GLOBAL from 'nonnglobal';
import customAxios from 'api/customAxios';

const API_URL = '/BonusAction.do';

function listBonusLogsStarted(payload) {
    return { type: actionTypes.listBonusLogsStarted, payload };
}
function listBonusLogsSuccess(result, refresh) {
    return {
        type: actionTypes.listBonusLogsSuccess,
        payload: {
            result,
            refresh,
        },
    };
}
function listBonusLogsFailed(payload) {
    return { type: actionTypes.listBonusLogsFailed, payload };
}
function listBonusLogs(payload = {}) {
    return dispatch => {

        dispatch(listBonusLogsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            const url = `${API_URL}?op=listBonusLogs&start=${payload.refresh ? 0 : payload.start}`;

            customAxios(url)
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return;
                            }

                            dispatch(listBonusLogsSuccess(result, payload.refresh));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listBonusLogsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listBonusLogsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function depositBonusPaidCardStarted(payload) {
    return { type: actionTypes.depositBonusPaidCardStarted, payload };
}
function depositBonusPaidCardSuccess(payload) {
    return { type: actionTypes.depositBonusPaidCardSuccess, payload };
}
function depositBonusPaidCardFailed(payload) {
    return { type: actionTypes.depositBonusPaidCardFailed, payload };
}
function depositBonusPaidCard(payload = {}) {
    return dispatch => {

        dispatch(depositBonusPaidCardStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'depositBonusPaidCard');
            form.append('bcardid', payload.bcardid);
            form.append('serialnum', payload.serialnum);
            form.append('passcode', payload.passcode);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(depositBonusPaidCardSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(depositBonusPaidCardFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(depositBonusPaidCardFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}



const actions = {
    listBonusLogs, // 發財商城交易記錄列表
    depositBonusPaidCard, // 儲值發財金點數

};

export default actions;