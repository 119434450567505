import webActionCreators from 'actions/creators/webActions';
import demoTestActionCreators from 'actions/creators/demoTestActions';
import idxPageActionCreators from 'actions/creators/idxPageActions';
import memberActionCreators from 'actions/creators/memberActions';
import bulletinActionCreators from 'actions/creators/bulletinActions';
import profitEventActionCreators from 'actions/creators/profitEventActions';
import cardActionCreators from 'actions/creators/cardActions';
import gameActionCreators from 'actions/creators/gameActions';
import vitemActionCreators from 'actions/creators/vitemActions';
import cardCartActionCreators from 'actions/creators/cardCartActions';
import cardOrderActionCreators from 'actions/creators/cardOrderActions';
import vitemOrderActionCreators from 'actions/creators/vitemOrderActions';
import bonusActionCreators from 'actions/creators/bonusActions';
import guestbookActionCreators from 'actions/creators/guestbookActions';
import registerActionCreators from 'actions/creators/registerActions';
import adActionCreators from 'actions/creators/adActions';

export default {
	webActionCreators,
	demoTestActionCreators,
	idxPageActionCreators,
	memberActionCreators,
	bulletinActionCreators,
	profitEventActionCreators,
	cardActionCreators,
	gameActionCreators,
	vitemActionCreators,
	cardCartActionCreators,
	cardOrderActionCreators,
	vitemOrderActionCreators,
	bonusActionCreators,
	guestbookActionCreators,
	registerActionCreators,
	adActionCreators,
};