import createWebReducers from './webReducers';
import createDemoTestReducers from './demoTestReducers';
import createIdxPageReducers from './idxPageReducers';
import createMemberReducers from './memberReducers';
import createBulletinReducers from './bulletinReducers';
import createProfitEventReducers from './profitEventReducers';
import createCardReducers from './cardReducers';
import createGameReducers from './gameReducers';
import createVitemReducers from './vitemReducers';
import createCardCartReducers from './cardCartReducers';
import createCardOrderReducers from './cardOrderReducers';
import createVitemOrderReducers from './vitemOrderReducers';
import createBonusReducers from './bonusReducers';
import createGuestbookReducers from './guestbookReducers';
import createRegisterReducers from './registerReducers';
import createSystemMsgReducers from './systemMsgReducers';

export default {
	createWebReducers,
	createDemoTestReducers,
	createIdxPageReducers,
	createMemberReducers,
	createBulletinReducers,
	createProfitEventReducers,
	createCardReducers,
	createGameReducers,
	createVitemReducers,
	createCardCartReducers,
	createCardOrderReducers,
	createVitemOrderReducers,
	createBonusReducers,
	createGuestbookReducers,
	createRegisterReducers,
	createSystemMsgReducers,

};
