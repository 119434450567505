import actionTypes from "actions/types/bulletinActionTypes";
import GLOBAL from 'nonnglobal';
import customAxios from 'api/customAxios';

const API_URL = '/BulletinAction.do';

// 列表公告
function listBulletinsStarted(payload) {
    return { type: actionTypes.listBulletinsStarted, payload };
}
function listBulletinsSuccess(payload) {
    return { type: actionTypes.listBulletinsSuccess, payload };
}
function listBulletinsFailed(payload) {
    return { type: actionTypes.listBulletinsFailed, payload };
}
function listBulletins(payload = {}) {
    return dispatch => {

        dispatch(listBulletinsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            const {
                start,
                bclass,
                len,
                startdate,
                enddate,
            } = payload;
            
            let form = new FormData();
            form.append('op', 'listBulletins');
            form.append('start', start);
            form.append('bclass', bclass);
            len && form.append('len', len);
            startdate && form.append('startdate', startdate);
            enddate && form.append('enddate', enddate);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listBulletinsSuccess({...result, bclass: payload.bclass}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listBulletinsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listBulletinsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

// 取得公告內容
function getBulletinDetailStarted(payload) {
    return { type: actionTypes.getBulletinDetailStarted, payload };
}
function getBulletinDetailSuccess(payload) {
    return { type: actionTypes.getBulletinDetailSuccess, payload };
}
function getBulletinDetailFailed(payload) {
    return { type: actionTypes.getBulletinDetailFailed, payload };
}
function getBulletinDetail(payload = {}) {
    return dispatch => {
        dispatch(getBulletinDetailStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            let form = new FormData();
            form.append('op', 'getBulletinDetail');
            form.append('bulletinid', payload.bulletinid);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(getBulletinDetailSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(getBulletinDetailFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(getBulletinDetailFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}



const actions = {
    listBulletins, // 列表公告
    getBulletinDetail, // 取得公告內容
};

export default actions;
