import actionTypes from "actions/types/gameActionTypes";
import convetDBResultSetToArray from "actions/util/convetDBResultSetToArray";
// import {URL, URLSearchParams} from 'universal-url';
import {uniq} from 'lodash';
/*==========================
    Reducers
==========================*/
const reducers = {

    [actionTypes.listGamesSuccess]: function(state, action) {
        return listGamesSuccess(state, action);
    },
    [actionTypes.getGameServerListSuccess]: function(state, action) {
        return getGameServerListSuccess(state, action);
    },
    [actionTypes.getGameMappingProductsSuccess]: function(state, action) {
        return getGameMappingProductsSuccess(state, action);
    },
    [actionTypes.getGameDetailSuccess]: function(state, action) {
        return getGameDetailSuccess(state, action);
    },
};



/*==========================
    Method
==========================*/


function getGameServerListSuccess (state, action) {
    const dbresult = convetDBResultSetToArray(action.payload.result);
    const { gameid } = action.payload;

    const gameSeverListById = Object.assign({}, state.gameSeverListById, {[gameid]: dbresult});

    let game = state.gameListMap[gameid] || {};
    let gameListMap = state.gameListMap;

    if(!game.defaultserverid){
        game = Object.assign({}, game, {
            gamename: dbresult[0].gamename,
            gameid: dbresult[0].gameid,
            defaultserverid: dbresult[0].gameserverid
        });
        gameListMap = Object.assign({}, gameListMap, {[gameid]: game});
    }

    return Object.assign({}, state, {
        gameListMap,
        gameSeverListById,
        isloading: false,
	});
}

function listGamesSuccess (state, action) {

    const dbresult = convetDBResultSetToArray(action.payload);

    const itemMap = dbresult.reduce((map, item)=>{
        let defaultserverid = undefined;
        if(item.gamelink){
            const searchParams = new URLSearchParams(item.gamelink);
            defaultserverid = searchParams.get('gameserverid');
        }
        map[item.gameid] = Object.assign({}, state.gameListMap[item.gameid], item, {defaultserverid});
        return map;
    }, {});
    

    const ids = dbresult.map((item, idx)=>{
        return item.gameid;
    });

    const newids = uniq([...state.gameListIds, ...ids]);

    const newItemMap = Object.assign({}, state.gameListMap, itemMap);

    return Object.assign({}, state, {
        gameListIds: newids,
        gameListMap: newItemMap,
        isloading: false,
	});
}

function getGameMappingProductsSuccess(state, { payload }) {

    const { result, key } = payload
    const { gameMappingProductIdsCache, gameMappingProductMap } = state;
    const gameMappingProductIds = gameMappingProductIdsCache[key] || {};
    const { ids = [] } = gameMappingProductIds;
    const dbresult = convetDBResultSetToArray(result);
    
    let new_gameMappingProductMap = Object.assign({}, gameMappingProductMap);

    const new_ids = dbresult.map(game => {

        const { productid } = game;

        new_gameMappingProductMap = Object.assign({}, new_gameMappingProductMap, {
            [productid]: game,
        });

        return productid;
    });

    const new_gameMappingProductIds = Object.assign({}, gameMappingProductIds, {
        ids: [...new Set([...ids, ...new_ids])],
        isEmpty: ids.length === 0 && new_ids.length === 0,
    });

    const new_gameMappingProductIdsCache = Object.assign({}, gameMappingProductIdsCache, {
        [key]: new_gameMappingProductIds,
    });


    const new_state = Object.assign({}, state, {
        gameMappingProductMap: new_gameMappingProductMap,
        gameMappingProductIdsCache: new_gameMappingProductIdsCache,
    });
    
    return new_state;
}

function getGameDetailSuccess(state, { payload }) {

    const {
        gameid,
        result,
    } = payload;

    const {
        gameListMap,
    } = state;

    const dbresult = convetDBResultSetToArray(result);

    if (!Array.isArray(dbresult) || dbresult.length === 0) {
        return state; 
    }

    const game = gameListMap[gameid] || {};
    const new_game = {
        ...game,
        ...dbresult[0],
    };

    const new_gameListMap = {
        ...gameListMap,
        [gameid]: new_game,
    };

    const newState = {
        ...state,
        gameListMap: new_gameListMap,
    };

    return newState;
}



// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}