import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducerCreators from 'actions/reducers/';
import { logger, crashReporter } from 'actions/actionMiddlewares';
import thunk from "redux-thunk";
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

const history = createBrowserHistory();

const middleWare = applyMiddleware(
    thunk,
    logger,
    crashReporter,
    routerMiddleware(history)
  );

const initWebState = {
    history,
    scrollPosition: {},
    renderedIndex: {},
    isCheckedWebState: false,
    // 登入視窗
    signInDialog: {
        isOpen: false,
    },
    // 提示視窗
    alertDialog: {
        isOpen: false,
        title: undefined,
        description: undefined,
        closeName: undefined,
        closeCallback: undefined,
    },
    // 確認視窗
    confirmDialog: {
        isOpen: false,
        title: undefined,
        description: undefined,
        isPromise: false,
        confirmCallback: undefined,
        confirmPromise: undefined,
        cancelCallback: undefined,
        closeCallback: undefined,
        confirmName: undefined,
        cancelName: undefined,
    },
    // 發票明細視窗
    invoiceDetailDialog: {
        isOpen: false,
        invoiceDetail : undefined,
        closeName: undefined,
        closeCallback: undefined,
    },
    // 忘記帳號 dialog
    forgotAccountDialog: {
        isOpen: false,
    },
    removeAccountDialog: {
        isOpen: false,
    },
    // 忘記密碼視窗
    forgetPwdDialog: {
        isOpen: false,
    },
    // 新增商品至購物車視窗
    addProductDialog: {
        isOpen: false,
    },
    // 登入訊息通知視窗
    systemTextMsgDialog: {
        isOpen: false,
    },
    // 購買發財商品 dialog
    buyBonusPdtDialog: {
        isOpen: false,
        productid: null,
        jsdproductid: null,
    },
    // Simple Alert Dialog
    simpleAlertDialog: {
        text: '',
        timestamp: null,
    },
};

const initDemoTestState = {
    ids: [],
    itemMap: {},
};
const initIdxPageState = {
    profitevents: [],
    bulletin1s: [],
    bulletin2s: [],
    hotgames: [],        //熱門遊戲
    hotcards: [],        //熱門產包
    hotbonuscards: [],   //熱門發財商城
    lotterys: [],
    topads: [],
    isloading: false,
    error: undefined,

    isLoadingIdxPageInfo: false,
    isLoadedIdxPageInfo: false,
    isLoadIdxPageInfoFailed: false,

    isLoadedIdxPageTopads: false,
}

const initMemberState = {
    haslogin: false,
    isloading: false,
    error: undefined,
    memberinfo: {},
    loginhistorys: [],
    msgLogMap: {},
    msgLogIds: [],
    tradeLogs: [],
    refundApplications: [],
    csCaseMap: {},
    csCaseIds: [],
    csClassMap: {},
    invoiceList: [],
    minfolastupdtime : -1,
}


const initRegisterState = {
    isloading: false,
    error: undefined,
    duplicateMemberID: false,
    duplicateEmail: false,
    duplicateMobilePhone: false,
    mobilePhoneVerified: undefined,
    emailVerified: undefined,
}

const initBulletinSstate = {
    isloading: false,
    error: undefined,
    itemMap: {},
    idsbyclass: {'1': [], '2': []}, // 依據公告分類來放置list ids
}


const initProfitEventSstate = {
    isloading: false,
    error: undefined,
    itemMap: {},
    ids: [],
}

const initCardState = {
    cardProviders: [],
    productGroups: [],
    cardProductMap: {},
    freeProductIds: [],
    vitemProductIds: [],
    bonusProductIds: [],
    cardProductIds: [],
    recommendedProductIds: [],
    cardProductIdsByGroupId: {},
    isloading: false,
    error: undefined,
    groupIsloading: false,
    groupError: undefined,
    ibonBlacklist: {},
}

const initGameState = {
    gameListMap: {},
    gameListIds: [],
    isloading: false,
    error: undefined,
    gameSeverListById: {},
    gameMappingProductIdsCache: {
        // [key]: { @key: see getGameMappingProductsCacheKey
        //     isEmpty: false,
        //     isError: false,
        // }
    },
    gameMappingProductMap: {},
}

const initVitemState = {
    sellerMap: {},
    sellerListIdsByGameAndServerId: {},
    isloading: false,
    error: undefined,
    cartOrder: {},
    purchaseMap: {},
    purchaseListIdsByGameAndServerId: {},
    purchaseFormData: undefined, // {},
}

const initCardCartState = {
    isloading: false,
    error: undefined,
    cartItems: [],
    cartProductImages: {},
    cartOrder: {},
    unfinishedCartMap: {},
    unfinishedCartIds: [],
}


const initCardOrderState = {
    isloading: false,
    error: undefined,
    orderMap: {},
    orderIdsMap: {
        'all': [],
    },
    jcardMap: {},
    jcardIdsByOrderId: {},
    bonusOrderIds: [],
}

const initVItemOrderState = {
    isloading: false,
    error: undefined,
    orderMap: {},
    orderIds: [],
    purchaseOrderMap: {},
    purchaseOrderIds: [],
    

}

const initBonusState = {
    isloading: false,
    error: undefined,
    logs: [],
}

const initGuestbookstate = {
    isloading: false,
    error: undefined,
    msgMap: {},
    // msgIds: [],
    msgIdsByVprovIds: {},
    guestMsgIds: [],
    guestMsgMap: {},
}

const initSystemMsgState = {

};

const actionReducers = combineReducers({
    web: reducerCreators.createWebReducers({}),
    demotest: reducerCreators.createDemoTestReducers({}),
    idxpage: reducerCreators.createIdxPageReducers({}),
    member: reducerCreators.createMemberReducers({}),
    bulletin: reducerCreators.createBulletinReducers({}),
    card: reducerCreators.createCardReducers({}),
    game: reducerCreators.createGameReducers({}),
    vitem: reducerCreators.createVitemReducers({}),
    cardcart: reducerCreators.createCardCartReducers({}),
    cardorder: reducerCreators.createCardOrderReducers({}),
    vitemorder: reducerCreators.createVitemOrderReducers({}),
    bonus: reducerCreators.createBonusReducers({}),
    guestbook: reducerCreators.createGuestbookReducers({}),
    profitevent: reducerCreators.createProfitEventReducers({}),
    register: reducerCreators.createRegisterReducers({}),
    systemMsg: reducerCreators.createSystemMsgReducers({}),
    router: connectRouter(history),
});

let actionStore = createStore(
    actionReducers,
    {
        web: initWebState,
        demotest: initDemoTestState,
        idxpage: initIdxPageState,
        member: initMemberState,
        register: initRegisterState,        
        bulletin: initBulletinSstate,
        profitevent: initProfitEventSstate,
        card: initCardState,
        game: initGameState,
        vitem: initVitemState,
        cardcart: initCardCartState,
        cardorder: initCardOrderState,
        vitemorder: initVItemOrderState,
        bonus: initBonusState,
        guestbook: initGuestbookstate,
        systemMsg: initSystemMsgState,
    },
    composeWithDevTools(middleWare)
);

export default actionStore;
