import styled from 'styled-components';
import Colors from './colors';
import GlobalStyle from "./globalstyle";

const Container = styled.div`
    width: ${GlobalStyle.SidebarWidth}px;
    min-width: ${GlobalStyle.SidebarWidth}px;
    margin-right: ${GlobalStyle.ViewSpace}px;
`;
const Section = GlobalStyle.BasicBlock.extend`
    margin-bottom: ${GlobalStyle.ViewSpace}px;
`;
const SectionTitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
    color: #4A4A4A;
    font-weight: 600;
    border-bottom: 1px solid #D9D9D9;
    cursor: pointer;
`;
const SpreadIcon = styled.div`
    font-size: 20px;
    color: #777;
`;
const Nav = styled.ul`
    margin: 0;
`;
const NavItemSimpleIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 100%;
    font-size: ${({ size = 12 }) => `${size}px`};
`;
const NavItemLinearGradientIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 19px;
    margin-right: 13px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: ${({ size = 12 }) => `${size}px`};
    color: #FFF;
    background-image: ${({ linearGradient }) => linearGradient ? `linear-gradient(${linearGradient})` : 'none'};
`;
const NavItemContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 44px;
`;
const NavItemText = styled.span`
`;
const NavItem = styled.li`
    background-color: ${({ isActive }) => isActive ? Colors.MAIN_COLOR : '#FFF'};
    font-size: 14px;
    cursor: pointer;
    user-select: none;

    ${NavItemText} {
        color: ${({ isActive }) => isActive ? '#FFF' : '#4A4A4A'};
    }

    ${NavItemLinearGradientIcon} {
        border: ${({ isActive }) => isActive ? '2px solid #FFF' : 'none'};
        ${({ isActive }) => isActive ?
            `background-image: none;`
            :
            ``
        }
    }

    ${NavItemSimpleIcon} {
        color: ${({ isActive }) => isActive ? '#FFF' : '#D9D9D9'};
    }
`;

const SideBarStyle = {
    Container,
    Section,
    SectionTitle,
    SpreadIcon,
    Nav,
    NavItemSimpleIcon,
    NavItemLinearGradientIcon,
    NavItemContent,
    NavItemText,
    NavItem,
};

export default SideBarStyle;