export default {
 
listProfitEvents: 'listProfitEvents',
listProfitEventsStarted: 'listProfitEventsStarted',
listProfitEventsSuccess: 'listProfitEventsSuccess',
listProfitEventsFailed: 'listProfitEventsFailed',


logProfitEventClick: 'logProfitEventClick',
logProfitEventClickStarted: 'logProfitEventClickStarted',
logProfitEventClickSuccess: 'logProfitEventClickSuccess',
logProfitEventClickFailed: 'logProfitEventClickFailed',

};
