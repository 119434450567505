export default {
 
    getVItemTopSellerListForGame: 'getVItemTopSellerListForGame',
    getVItemTopSellerListForGameStarted: 'getVItemTopSellerListForGameStarted',
    getVItemTopSellerListForGameSuccess: 'getVItemTopSellerListForGameSuccess',
    getVItemTopSellerListForGameFailed: 'getVItemTopSellerListForGameFailed',

    getVItemSellerListForGame: 'getVItemSellerListForGame',
    getVItemSellerListForGameStarted: 'getVItemSellerListForGameStarted',
    getVItemSellerListForGameSuccess: 'getVItemSellerListForGameSuccess',
    getVItemSellerListForGameFailed: 'getVItemSellerListForGameFailed',

    initOrder: 'initOrder',
    initOrderStarted: 'initOrderStarted',
    initOrderSuccess: 'initOrderSuccess',
    initOrderFailed: 'initOrderFailed',

    buildVItemCartOrder: 'buildVItemCartOrder',
    buildVItemCartOrderStarted: 'buildVItemCartOrderStarted',
    buildVItemCartOrderSuccess: 'buildVItemCartOrderSuccess',
    buildVItemCartOrderFailed: 'buildVItemCartOrderFailed',

    payVItemCart: 'payVItemCart',
    payVItemCartStarted: 'payVItemCartStarted',
    payVItemCartSuccess: 'payVItemCartSuccess',
    payVItemCartFailed: 'payVItemCartFailed',

    listTopPurchases: 'listTopPurchases',
    listTopPurchasesStarted: 'listTopPurchasesStarted',
    listTopPurchasesSuccess: 'listTopPurchasesSuccess',
    listTopPurchasesFailed: 'listTopPurchasesFailed',

    listPurchases: 'listPurchases',
    listPurchasesStarted: 'listPurchasesStarted',
    listPurchasesSuccess: 'listPurchasesSuccess',
    listPurchasesFailed: 'listPurchasesFailed',

    addPurchaseOrder: 'addPurchaseOrder',
    addPurchaseOrderStarted: 'addPurchaseOrderStarted',
    addPurchaseOrderSuccess: 'addPurchaseOrderSuccess',
    addPurchaseOrderFailed: 'addPurchaseOrderFailed',

    initPurchaseOrder: 'initPurchaseOrder',
    initPurchaseOrderStarted: 'initPurchaseOrderStarted',
    initPurchaseOrderSuccess: 'initPurchaseOrderSuccess',
    initPurchaseOrderFailed: 'initPurchaseOrderFailed',

    addPurchaseFormData: 'addPurchaseFormData',
};
