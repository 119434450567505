export default {
	storeScrollPosition: 'storeScrollPosition',
	storeRenderedIndex: 'storeRenderedIndex',
	showSignInDialog: 'showSignInDialog',
	hideSignInDialog: 'hideSignInDialog',
	openAlertDialog: 'openAlertDialog',
	closeAlertDialog: 'closeAlertDialog',
	openConfirmDialog: 'openConfirmDialog',
	closeConfirmDialog: 'closeConfirmDialog',
	showSimpleAlertDialog: 'showSimpleAlertDialog',
	openInvoiceDetailDialog: 'openInvoiceDetailDialog',
	closeInvoiceDetailDialog: 'closeInvoiceDetailDialog',
	openForgotAccountDialog: 'openForgotAccountDialog',
	openRemoveAccountDialog: 'openRemoveAccountDialog',
	closeForgotAccountDialog: 'closeForgotAccountDialog',
	closeRemoveAccountDialog: 'closeRemoveAccountDialog',
	openForgetPwdDialog: 'openForgetPwdDialog',
	closeForgetPwdDialog: 'closeForgetPwdDialog',
	openAddProductDialog: 'openAddProductDialog',
	closeAddProductDialog: 'closeAddProductDialog',
	openSystemTextMsgDialog: 'openSystemTextMsgDialog',
	closeSystemTextMsgDialog: 'closeSystemTextMsgDialog',
	checkWebStateSuccess: 'checkWebStateSuccess',
	openBuyBonusPdtDialog: 'openBuyBonusPdtDialog',
	closeBuyBonusPdtDialog: 'closeBuyBonusPdtDialog',
};
