export default {
 
listBulletins: 'listBulletins',
listBulletinsStarted: 'listBulletinsStarted',
listBulletinsSuccess: 'listBulletinsSuccess',
listBulletinsFailed: 'listBulletinsFailed',

getBulletinDetail: 'getBulletinDetail',
getBulletinDetailStarted: 'getBulletinDetailStarted',
getBulletinDetailSuccess: 'getBulletinDetailSuccess',
getBulletinDetailFailed: 'getBulletinDetailFailed',

};
