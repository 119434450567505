export default {
//  listMsgContents: 'listMsgContents',
//  listMsgContentsStarted: 'listMsgContentsStarted',
//  listMsgContentsSuccess: 'listMsgContentsSuccess',
//  listMsgContentsFailed: 'listMsgContentsFailed',

//  addMsgContent: 'addMsgContent',
//  addMsgContentStarted: 'addMsgContentStarted',
//  addMsgContentSuccess: 'addMsgContentSuccess',
//  addMsgContentFailed: 'addMsgContentFailed',

    listVprovMsgContents: 'listVprovMsgContents',
    listVprovMsgContentsStarted: 'listVprovMsgContentsStarted',
    listVprovMsgContentsSuccess: 'listVprovMsgContentsSuccess',
    listVprovMsgContentsFailed: 'listVprovMsgContentsFailed',

    addVprovMsgContent: 'addVprovMsgContent',
    addVprovMsgContentStarted: 'addVprovMsgContentStarted',
    addVprovMsgContentSuccess: 'addVprovMsgContentSuccess',
    addVprovMsgContentFailed: 'addVprovMsgContentFailed',

    listMsgContentsStarted: 'listMsgContentsStarted',
    listMsgContentsSuccess: 'listMsgContentsSuccess',
    listMsgContentsFailed: 'listMsgContentsFailed',
};
