/*=====================================
    全域共用樣式

    Author: Gray
    createtime: 2017 / 12 / 21
=====================================*/

/*--------------------------
    Import 
--------------------------*/
import styled, { keyframes } from 'styled-components';
import Colors from './colors';

/*--------------------------
    Style Variables
--------------------------*/
const ContainerWidth = 'calc(100% - 40px)';
const ContainerMaxWidth = 1280;
const TopbarHeight = 119;
const ViewSpace = 10; // 預設的留白空間
const TopbarZindex = 950;
const SidebarWidth = 244;
/*--------------------------
    Style Keyframes
--------------------------*/
const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

/*--------------------------
    Style Component
--------------------------*/
const Topbar = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
	width: 100%;
    min-width: ${ContainerMaxWidth}px;
    height: ${TopbarHeight}px;
    border-bottom: 1px solid #DDDDDD;
    background-color: #FFF;
    z-index: ${TopbarZindex};
    transform: translateZ(0);
	box-shadow: inset 0 -1px 1px 0 rgba(218,218,218,0.50);
`;
const Container = styled.div`
	position: relative;
	padding: ${TopbarHeight}px 0 0;
	width: 100%;
	min-height: 100vh;
	height: 100%;
`;
const SubContainer = styled.div`
	position: relative;
	width: ${ContainerWidth};
	width: ${ContainerMaxWidth}px;
	height: 100%;
	margin: 0 auto;
`;
const Spin = styled.div`
	position: relative;
	vertical-align: sub;
	display: ${props => props.display ? props.display : 'inline-block'};
	width: ${props => props.size ? props.size : '20px'};
	height: ${props => props.size ? props.size : '20px'};
	margin: ${props => props.margin ? props.margin : '0 auto'};
	border-width: ${props => props.borderWidth ? props.borderWidth : '2px'};
	border-style: solid;
	border-top-color: ${props => props.bgColor ? props.bgColor : 'transparent'};
	border-left-color: ${props => props.bgColor ? props.bgColor : 'transparent'};
	border-right-color: ${props => props.color ? props.color : Colors.MAIN_COLOR};
	border-bottom-color: ${props => props.color ? props.color : Colors.MAIN_COLOR};
	border-radius: 100%;
	animation: ${rotate360} 0.6s linear infinite;
`;
const LoadingContainer = styled.div`
	display: flex;
	width: 100%;
	height: 112px;
	align-items: center;
	justify-content: center;
`;
const ErrorContainer = styled.div`
	width: 100%;	
	text-align: center;
	
	${(props) => {
		if(props.padding) {
			return `
				padding: ${props.padding};
			`
		}
		return `padding: 160px 0;`;
	}}
`;
const ErrorContainerTitle = styled.div`
	max-width: 500px;
	margin: 0 auto 10px auto;
	padding: 0 20px;
	color: ${Colors.Dark2};
	letter-spacing: 0;
	font-size: 20px;
	font-weight: 600;
`;
const ErrorContainerDesription = styled.div`
	max-width: 500px;
	line-height: 24px;
	margin: 0 auto;
	padding: 0 20px;
	color: ${Colors.Dark3};
	letter-spacing: 0;
	font-size: 16px;
`;
const BasicBlock = styled.div`
	width: 100%;
	background: #FFF;
	border: 1px solid #D9D9D9;
	border-radius: 5px;
	overflow: hidden;
`;
const BasicInput = styled.input`
    width: 100%;
    border-width: 1px;
	border-style: solid;
	border-color: ${({ error }) => error ? Colors.Red : '#DDD'};
    border-radius: 2px;
    padding: 11px 12px;
    background-color: #FAFAFA;
`;
const BasicBtn = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
    height: 44px;
    border-radius: 2px;
	border: 1px solid ${Colors.MAIN_COLOR};
    background-color: ${({ outline }) => outline ? 'transparent' : Colors.MAIN_COLOR};
	color: ${({ outline }) => outline ? Colors.MAIN_COLOR : '#FFF'};
    font-size: 16px;
	cursor: pointer;

	${props => {
        if(props.disable) {
            return `
                cursor: default;
                opacity: 0.5;
            `;
        } else {
            return `
                cursor: pointer;
                opacity: 1;
            `;
        }
    }}
`;
const LoadingSpinCenter = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
`;

const GlobalStyle = {
	/*--------------------------
		Variables
	--------------------------*/
	ContainerWidth,
	ContainerMaxWidth,
	TopbarHeight,
	ViewSpace,
	TopbarZindex,
	SidebarWidth,

	/*--------------------------
		Component
	--------------------------*/
	Topbar,
	Container,
	SubContainer,
	Spin,
	LoadingContainer,
	ErrorContainer,
	ErrorContainerTitle,
	ErrorContainerDesription,
	BasicBlock,
	BasicInput,
	BasicBtn,
	LoadingSpinCenter,
}

export default GlobalStyle;