import actionTypes from "actions/types/cardCartActionTypes";
import GLOBAL from 'nonnglobal';
import customAxios from 'api/customAxios';

const API_URL = '/CardCartAction.do';

function buildCartOrderStarted(payload) {
    return { type: actionTypes.buildCartOrderStarted, payload };
}
function buildCartOrderSuccess(payload) {
    return { type: actionTypes.buildCartOrderSuccess, payload };
}
function buildCartOrderFailed(payload) {
    return { type: actionTypes.buildCartOrderFailed, payload };
}
function buildCartOrder(payload = {}) {
    return dispatch => {

        dispatch(buildCartOrderStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            
            let form = new FormData();
            form.append('op', 'buildCartOrder');
            
            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(buildCartOrderSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(buildCartOrderFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(buildCartOrderFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}



function addCardToCartStarted(payload) {
    return { type: actionTypes.addCardToCartStarted, payload };
}
function addCardToCartSuccess(payload) {
    return { type: actionTypes.addCardToCartSuccess, payload };
}
function addCardToCartFailed(payload) {
    return { type: actionTypes.addCardToCartFailed, payload };
}
function addCardToCart(payload = {}) {
    return dispatch => {

        dispatch(addCardToCartStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'addCardToCart');
            form.append('productid', payload.productid);
            form.append('count', payload.count);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(addCardToCartSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(addCardToCartFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(addCardToCartFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function delCardFromCartStarted(payload) {
    return { type: actionTypes.delCardFromCartStarted, payload };
}
function delCardFromCartSuccess(payload) {
    return { type: actionTypes.delCardFromCartSuccess, payload };
}
function delCardFromCartFailed(payload) {
    return { type: actionTypes.delCardFromCartFailed, payload };
}
function delCardFromCart(payload = {}) {
    return dispatch => {

        dispatch(delCardFromCartStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            
            let form = new FormData();
            form.append('op', 'delCardFromCart');
            form.append('productid', payload.productid);
            form.append('createtime', payload.createtime);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(delCardFromCartSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(delCardFromCartFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(delCardFromCartFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listCardCartItemsStarted(payload) {
    return { type: actionTypes.listCardCartItemsStarted, payload };
}
function listCardCartItemsSuccess(payload) {
    return { type: actionTypes.listCardCartItemsSuccess, payload };
}
function listCardCartItemsFailed(payload) {
    return { type: actionTypes.listCardCartItemsFailed, payload };
}
function listCardCartItems(payload = {}) {
    return dispatch => {

        dispatch(listCardCartItemsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listCardCartItems');
            

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listCardCartItemsSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listCardCartItemsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listCardCartItemsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


function payCardCartStarted(payload) {
    return { type: actionTypes.payCardCartStarted, payload };
}
function payCardCartSuccess(payload) {
    return { type: actionTypes.payCardCartSuccess, payload };
}
function payCardCartFailed(payload) {
    return { type: actionTypes.payCardCartFailed, payload };
}
function payCardCart(payload = {}) {
    return dispatch => {

        dispatch(payCardCartStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'payCardCart');
            form.append('paytype', payload.paytype);
            

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(payCardCartSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(payCardCartFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(payCardCartFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listCardCartUnfinishedOrdersStarted(payload) {
    return { type: actionTypes.listCardCartUnfinishedOrdersStarted, payload };
}
function listCardCartUnfinishedOrdersSuccess(payload) {
    return { type: actionTypes.listCardCartUnfinishedOrdersSuccess, payload };
}
function listCardCartUnfinishedOrdersFailed(payload) {
    return { type: actionTypes.listCardCartUnfinishedOrdersFailed, payload };
}
function listCardCartUnfinishedOrders(payload = {}) {
    return dispatch => {

        dispatch(listCardCartUnfinishedOrdersStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            let form = new FormData();
            form.append('op', 'listCardCartUnfinishedOrders');
            form.append('start', payload.start);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                dispatch(listCardCartUnfinishedOrdersFailed(result.error));
                                reject(result.error);
                                return 
                            }

                            dispatch(listCardCartUnfinishedOrdersSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listCardCartUnfinishedOrdersFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listCardCartUnfinishedOrdersFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function payCardCartUnfinishedOrderStarted(payload) {
    return { type: actionTypes.payCardCartUnfinishedOrderStarted, payload };
}
function payCardCartUnfinishedOrderSuccess(payload) {
    return { type: actionTypes.payCardCartUnfinishedOrderSuccess, payload };
}
function payCardCartUnfinishedOrderFailed(payload) {
    return { type: actionTypes.payCardCartUnfinishedOrderFailed, payload };
}
function payCardCartUnfinishedOrder(payload = {}) {
    return dispatch => {

        dispatch(payCardCartUnfinishedOrderStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'payCardCartUnfinishedOrder');
            form.append('cartid', payload.cartid);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(payCardCartUnfinishedOrderSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(payCardCartUnfinishedOrderFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(payCardCartUnfinishedOrderFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


function fetchCartProductImageStarted(payload){
    return {
        type: actionTypes.fetchCartProductImageStarted,
        payload,
    }
}
function fetchCartProductImageSuccess(payload){
    return {
        type: actionTypes.fetchCartProductImageSuccess,
        payload,
    }
}
function fetchCartProductImageFailed(payload){
    return {
        type: actionTypes.fetchCartProductImageFailed,
        payload,
    }
}

function fetchCartProductImage(payload = {}){

    return dispatch => {

        const {
            productIds = [],
        } = payload;

        const _productIds = productIds.reduce((str, id, idx) => {
            return idx === productIds.length - 1 ? str += id : str += `${id},`;
        }, '');
        
        dispatch(fetchCartProductImageStarted());

        const promise = new Promise((resolve, reject) => {

            const form = new FormData();

            form.append('op', 'listCardCartProductimgs');
            form.append('productids', _productIds);
                
            customAxios(API_URL, {
                method: 'POST',
                body: form,
            }).then(response => {

                response.json().then(result => {
                    if(result && result.error){
                        dispatch(fetchCartProductImageFailed(result.error));
                        reject(result.error);
                    } else {
                        dispatch(fetchCartProductImageSuccess({ result }));
                        resolve(result);
                    }
                }).catch(error => {
                    dispatch(fetchCartProductImageFailed(error));
                    reject(error);
                });
            })
            .catch(error => {
                dispatch(fetchCartProductImageFailed(error));
                reject(error);
            });
                
        });
        return promise;
    }
}
//========================================
function chkCreditCardLimitStarted(payload) {
    return { type: actionTypes.chkCreditCardLimitStarted, payload };
}
function chkCreditCardLimitSuccess(payload) {
    return { type: actionTypes.chkCreditCardLimitSuccess, payload };
}
function chkCreditCardLimitFailed(payload) {
    return { type: actionTypes.chkCreditCardLimitFailed, payload };
}
function chkCreditCardLimit(payload = {}) {
    return dispatch => {

        dispatch(buildCartOrderStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {
            
            let form = new FormData();
            form.append('op', 'chkCreditCartLimit');
            
            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(chkCreditCardLimitSuccess(result));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(chkCreditCardLimitFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(buildCartOrderFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}


const actions = {
    addCardToCart, // 點卡商品加入購物車
    delCardFromCart, // 刪除購物車中的點卡商品
    listCardCartItems, // 點卡購物車商品列表
    buildCartOrder, // 點卡購物車建立訂單（結帳步驟1）
    payCardCart, // 購物車選擇付款方式（結帳步驟2）
    listCardCartUnfinishedOrders, // 列出未結帳購物車訂單
    payCardCartUnfinishedOrder, // 取得未結帳購物車訂單付款資訊
    fetchCartProductImage, // 取得購物車內商品圖片
    chkCreditCardLimit,   //確認是否可使用信用卡
};

export default actions;