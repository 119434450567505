import actionTypes from "actions/types/registerActionTypes";

/*==========================
    Reducers
==========================*/
const reducers = {
	[actionTypes.registerMemberSuccess]: function(state, action) {
        return registerMemberSuccess(state, action);
    },
    [actionTypes.checkPhoneSuccess]: function(state, action) {
        return checkPhoneSuccess(state, action);
    },
    [actionTypes.checkEmailSuccess]: function(state, action) {
        return checkEmailSuccess(state, action);
    },
    [actionTypes.sendSmsAuthCodeSuccess]: function(state, action) {
        return sendSmsAuthCodeSuccess(state, action);
    },
    [actionTypes.verifyRegisterSmsSuccess]: function(state, action) {
        return verifyRegisterSmsSuccess(state, action);
    },
    [actionTypes.verifyRegisterEmailSuccess]: function(state, action) {
        return verifyRegisterEmailSuccess(state, action);
    },
};



/*==========================
    Method
==========================*/

function verifyRegisterEmailSuccess (state, action) {
    return Object.assign({}, state, {
        emailVerified: action.payload.result,
        isloading: false,
	});
}

function verifyRegisterSmsSuccess (state, action) {
    return Object.assign({}, state, {
        mobilePhoneVerified: action.payload.result,
        isloading: false,
	});
}
function sendSmsAuthCodeSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
	});
}

function checkEmailSuccess (state, action) {
    return Object.assign({}, state, {
        duplicateEmail: action.payload.result,
        isloading: false,
	});
}

function checkPhoneSuccess (state, action) {
    return Object.assign({}, state, {
        duplicateMobilePhone: action.payload.result,
        isloading: false,
	});
}

function registerMemberSuccess (state, action) {
    return Object.assign({}, state, {
        isloading: false,
	});
}


// 操作失敗
function actionFailed (state, action) {

    if(state.error === action.payload){
        return state;
    }

    const error = action.payload;

    return Object.assign({}, state, {
        isloading: false,
        error,
	});


}


// 操作中
function actionStarted (state, action) {

    if(state.isloading){
        return state;
    }

    return Object.assign({}, state, {
        isloading: true,
	});


}


/*==========================
    Export
==========================*/
export default function createReducers (initialState) {
    
	return function reducer(state = initialState, action) {
        if(!actionTypes.hasOwnProperty(action.type)){
            return state;
        }

        // console.log('actionTypes.hasOwnProperty(action.type)',actionTypes.hasOwnProperty(action.type), action.type);
		if (reducers.hasOwnProperty(action.type)) {
            return reducers[action.type](state, action);
		} else if(action.type.lastIndexOf('Started') !== -1){            
			return actionStarted(state, action);            
		} else if(action.type.lastIndexOf('Failed') !== -1){
			return actionFailed(state, action);            
        } else {
			return state;
		}
	};
}