import GlobalStyle from './globalstyle';
import Colors from './colors';
import styled from 'styled-components';

const Container = GlobalStyle.BasicBlock.extend`
    padding: 30px;
`;
const Title = styled.h3`
    color: #003366;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
`;
const SubTitle = styled.h4`
    color: ${Colors.Dark3};
    font-size: 14px;
    font-weight: bold;
    margin: 0;
`;
const List = styled.ul`
    font-size: 14px;
    color: ${Colors.Dark3};
    line-height: 24px;
    text-align: justify;
`;

const Section = styled.div`
    margin-top: 20px;
    font-size: 14px;
    color: ${Colors.Dark3};
    line-height: 24px;
    text-align: justify;
`;
const SubSection = Section.extend`
    margin-top: 10px;
    margin-left: 10px;
`;
const AnswerText = styled.span`
    color: #339900;
`;
const RedText = styled.span`
    color: #F00;
`;
const NoticeText = styled.div`
    color: #F00;
`;
const BoldText = styled.div`
    color: ${Colors.Dark3};
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
`;
const StepText = styled.span`
    display: inline-block;
    margin-top: 10px;
    color: #F00;
`;
const BlueText = styled.span`
    color: #443AB7;
`;
const Image = styled.div`
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const Table = styled.table`
    border: 1px solid #CCC;

    th, td {
        border: 1px solid #DDD;
        text-align: center;
        vertical-align: middle;
        padding: 10px;
    }
`;

const HelpCenterStyle = {
    Container,
    Title,
    SubTitle,
    Section,
    SubSection,
    AnswerText,
    NoticeText,
    RedText,
    BoldText,
    StepText,
    BlueText,
    Image,
    Table,
};

export default HelpCenterStyle;