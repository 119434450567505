import axios from 'axios';
import React from 'react';
import { render as reactDOMRender } from 'react-dom';

import store from '../actions/store/configureStore';
import memberActions from '../actions/creators/memberActions';
import RepairView from '../components/RepairView';

const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  timeout: 20000,
});

// axiosInstance.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   return config;
// }, function (error) {
//   // Do something with request error
//   // console.log(error);
//   console.log(error);
//   return Promise.reject(error);
// });

axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (response.data?.error) {

    const {
      data,
      config,
    } = response;

    // match 9199 server url 
    if (config?.url && config.url.startsWith('/')) {
      switch (data.error?.code) {
        // 回傳請先登入時
        case 'error.privilege.noLogin':
          store.dispatch(memberActions.loginStatusExpired());
          break;
        default:
          break;
      }
    }

    return Promise.reject(data.error);
  }

  const _response = {
    ...response,
    json: () => Promise.resolve(response.data),
    text: () => Promise.resolve(JSON.stringify(response.data)),
  };

  return _response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error

  if (axios.isCancel(error)) {
    return;
  }

  switch (error.response?.status) {
    case 503: {

      const { url } = error.config;

      // 9199 server resp 503 顯示維修頁
      if (url && url.startsWith('/')) {

        // RepairView id
        const hadShowRepair = !!document.getElementById('maintain');

        if (!hadShowRepair) {
          const rootElement = document.getElementById('root');
          reactDOMRender(<RepairView />, rootElement);
        }
      }
      break;
    }
    default:
      break;
  }
  return Promise.reject(error);
});

function customAxios(url, options) {

  let _options = {};
  if (options && options.body) {

    const {
      body,
      ...others
    } = options;

    _options = {
      data: body,
      ...others,
    };
  } else if (options) {
    _options = options;
  }

  return axiosInstance({
    url,
    ..._options,
  });
};

export default customAxios;
