/*=====================================
    FloatBar

    Author: Ac
    CreateTime: 2018 / 08 / 16
=====================================*/

/*--------------------------
    Import
--------------------------*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GlobalStyle, Colors } from 'stylecomponents';
import CupoyRouter from 'components/commons/router/CupoyRouter';
import CupoySrefView from 'components/commons/router/CupoySrefView';
import CupoyImage from 'components/commons/CupoyImage';

/*--------------------------
    Styled
--------------------------*/
const Container = styled.div`
    position: fixed;
    left: ${`calc(50% + ${GlobalStyle.ContainerMaxWidth / 2}px + ${GlobalStyle.ViewSpace}px)`};
    top: 50%;
    width: 64px;
    height: 165px;
`;
const GoTop = styled.div`
    position: absolute;
    top: ${({ haslogin }) => haslogin ? '165px' : '101px'};
    left: 0;
    width: 100%;
    height: 64px;
    background-color: #4A4A4A;
    color: #FFF;
    font-size: 12px;
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
`;
const GoTopIcon = styled.div`
    padding-top: 8px;
    padding-bottom: 2px;
    width: 100%;
    font-size: ${({ size }) => size ? `${size}px` : '12px'};

    &::before {
        content: '';
        display: block;
        width: 24px;
        height: 3px;
        background-color: #FFF;
        margin: 0 auto;
    }    
`;
const Cart = GoTop.extend`
    top: 101px;
    background-color: rgba(247,92,47, .8);
    font-weight: 600;
`;
const CartIcon = styled.div`
    padding-top: 8px;
    padding-bottom: 2px;
    width: 100%;
    font-size: ${({ size }) => size ? `${size}px` : '12px'};
`;
const AppQRCode = styled.div`
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    background-color: #FFF;
    border-radius: 3px;
    margin-bottom: 10px;
`;
const AppQRCodeTitle = styled.div`
    color: ${Colors.MAIN_COLOR};
    padding: 4px 0 2px 6px;
    font-size: 12px;
`;
const AppQRCodeCloseIcon = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    color: #9B9B9B;
    font-size: ${({ size }) => size ? `${size}px` : '12px'};
    cursor: pointer;
`;
const AppQRCodeImage = styled.div`
    width: 65px;
    height: 65px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
`;
/*--------------------------
    Main Component
--------------------------*/
class FloatBar extends Component {

    static propTypes = {
        haslogin: PropTypes.bool,
        cartCount: PropTypes.number,
    };

    static defaultProps = {
        haslogin: false,
        cartCount: 0,
    };

    constructor(props) {

        super(props);

        this.scrollTop = this.scrollTop.bind(this);
        this.hideQRCode = this.hideQRCode.bind(this);

        this.state = {
            isShowQRCode: true,
        };
    }

    scrollTop (){
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    hideQRCode (){
        this.setState({
            isShowQRCode: false,
        });
    }
    
    render() {

        const  { isShowQRCode } = this.state;
        const { haslogin, cartCount } = this.props;

        return (
            <Container>
                {isShowQRCode && this.renderAppQRCode()}
                {haslogin && this.renderCart(cartCount)}
                {this.renderGoTop(haslogin)}
            </Container>
        );
    }

    renderCart = cartCount => (
        <CupoySrefView
            stylecomponent={Cart}
            statename={CupoyRouter.STATES.MEMBER_CART}
        >
            <CartIcon size={34}>
                <i className="zmdi zmdi-shopping-cart"></i>
            </CartIcon>
            {`購物車(${cartCount})`}
        </CupoySrefView>
    );

    renderAppQRCode = () => (
        <AppQRCode>
            <AppQRCodeTitle>APP</AppQRCodeTitle>
            <AppQRCodeCloseIcon size={13} onClick={this.hideQRCode}>
                <i className="zmdi zmdi-close"></i>
            </AppQRCodeCloseIcon>
            <CupoyImage 
                src={'images/google-play-nonn.svg'}
                stylecomponent={AppQRCodeImage}
                isBackgroundImage
            />
        </AppQRCode>
    );

    renderGoTop = (haslogin) => (
        <GoTop onClick={this.scrollTop} haslogin={haslogin}>
            <GoTopIcon size={30}>
                <i className="zmdi zmdi-long-arrow-up"></i>
            </GoTopIcon>
            回到頂部
        </GoTop>
    );

}

export default FloatBar;