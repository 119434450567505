import actionTypes from "actions/types/guestbookActionTypes";
import GLOBAL from 'nonnglobal';
import customAxios from 'api/customAxios';

const API_URL = '/GuestBookAction.do';

function listVprovMsgContentsStarted(payload) {
    return { type: actionTypes.listVprovMsgContentsStarted, payload };
}
function listVprovMsgContentsSuccess(payload) {
    return { type: actionTypes.listVprovMsgContentsSuccess, payload };
}
function listVprovMsgContentsFailed(payload) {
    return { type: actionTypes.listVprovMsgContentsFailed, payload };
}
function listVprovMsgContents(payload = {}) {

    return dispatch => {

        const {
            start,
            viprovid,
        } = payload;

        dispatch(listVprovMsgContentsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listVprovMsgContents');
            form.append('start', start);
            form.append('viprovid', viprovid);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(listVprovMsgContentsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listVprovMsgContentsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listVprovMsgContentsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function addVprovMsgContentStarted(payload) {
    return { type: actionTypes.addVprovMsgContentStarted, payload };
}
function addVprovMsgContentSuccess(payload) {
    return { type: actionTypes.addVprovMsgContentSuccess, payload };
}
function addVprovMsgContentFailed(payload) {
    return { type: actionTypes.addVprovMsgContentFailed, payload };
}
function addVprovMsgContent(payload = {}) {
    return dispatch => {

        dispatch(addVprovMsgContentStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'addVprovMsgContent');
            form.append('passcode', payload.passcode);
            form.append('viprovid', payload.viprovid);
            form.append('gameid', payload.gameid);
            form.append('gameserverid', payload.gameserverid);
            form.append('sex', payload.sex);
            form.append('msgcontent', payload.msgcontent);


            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                reject();
                                return;
                            }

                            if(result.error) {
                                reject(result.error);
                                return 
                            }

                            dispatch(addVprovMsgContentSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(addVprovMsgContentFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(addVprovMsgContentFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function listMsgContentsStarted(payload) {
    return { type: actionTypes.listMsgContentsStarted, payload };
}
function listMsgContentsSuccess(payload) {
    return { type: actionTypes.listMsgContentsSuccess, payload };
}
function listMsgContentsFailed(payload) {
    return { type: actionTypes.listMsgContentsFailed, payload };
}
function listMsgContents(payload = {}) {

    return dispatch => {

        const {
            start,
            len,
        } = payload;

        dispatch(listMsgContentsStarted(payload)); // dispatch synced action to update loading status

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'listMsgContents');
            start && form.append('start', start);
            len && form.append('len', len);

            customAxios(API_URL,
                    {
                        method: "POST",
                        body: form
                    }
            )
                .then(response => {
                    
                    if (response.status === 503) {
                        // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                        reject({
                            error: 'error.503error',
                            message: '伺服器沒有開啟'
                        });

                        return;
                    }
                    response
                        .json()
                        .then(result => {
                            if (!result) {
                                dispatch(listMsgContentsFailed({}));
                                reject();
                                return;
                            }

                            if(result.error) {
                                dispatch(listMsgContentsFailed(result.error));
                                reject(result.error);
                                return;
                            }

                            dispatch(listMsgContentsSuccess({result, ...payload}));
                            resolve(result);
                        })
                        .catch(error => {
                            dispatch(listMsgContentsFailed(error));
                            reject(error);
                        });
                })
                .catch(error => {
                    // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                    dispatch(listMsgContentsFailed(error));
                    reject(error);
                });
        });
        return promise;
    };
}

function addMsgContent(payload = {}) {

    return () => {

        const {
            memberid,
            msgcontent,
            passcode,
        } = payload;

        const promise = new Promise((resolve, reject) => {

            
            let form = new FormData();
            form.append('op', 'addMsgContent');
            form.append('memberid', memberid);
            form.append('msgcontent', msgcontent);
            form.append('passcode', passcode);

            customAxios(API_URL,
                {
                    method: "POST",
                    body: form
                }
            ).then(response => {
                    
                if (response.status === 503) {
                    // server正常，apache也存活，但是resin已經被關閉的情況下會出現此錯誤
                    reject({
                        error: 'error.503error',
                        message: '伺服器沒有開啟'
                    });

                    return;
                }
                response
                    .json()
                    .then(result => {
                        if (!result) {
                            reject();
                            return;
                        }

                        if(result.error) {
                            reject(result.error);
                            return;
                        }

                        resolve(result);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
            .catch(error => {
                // 手機沒有開網路、錯誤的連線網址或是server完全無法連線（包含AWS以及apache都沒有開的狀態)才會出現此錯誤
                reject(error);
            });
        });
        return promise;
    };
}

const actions = {
    listVprovMsgContents, // 幣商留言列表
    addVprovMsgContent, // 新增對幣商留言
    listMsgContents, // 訪客留言列表
    addMsgContent, // 新增訪客留言
};

export default actions;